<template>
  <div>
    <div :class="[flag ? 'mainNavs' : 'mainNav']">
      <div class="top" style="width: 1200px; margin: 0 auto">
        <div class="topLeft">
          <div class="topLeft1"></div>
          <div class="topLeft2">
            <div :class="[flag ? 'topLeft2-1s' : 'topLeft2-1']">
              <!-- 方大智能管家 -->
            </div>
            <div :class="[flag ? 'topLeft2-2s' : 'topLeft2-2']">
              <!-- Foundation Smart Manager -->
            </div>
          </div>
        </div>
        <div class="router-hover">
          <router-link
            :class="[
              flag ? 'topCenter-routers' : 'topCenter-router',
              $route.path === '/homepage' ? 'highlight' : '',
              $route.path === '/homepage' && flag ? 'highlights' : '',
            ]"
            to="/homepage"
            >首页</router-link
          >
          <!-- <div class="hover-content">
              <div class="hover-contents">
                <div class="flex jc-space ai">
                  <div>
                    <router-link class="router-link" to="/qiXunBao">企讯宝</router-link>
                  </div>
                  <div>
                    <router-link class="router-link" to="/qiXunCloudAi">企讯云Ai</router-link>
                  </div>
                  <div>
                    <router-link class="" to="/productcenter">企讯云+</router-link>
                  </div>
                </div>
              </div>
            </div> -->
        </div>
        <div class="router-hover" style="width: 120px; text-align: center">
          <router-link
            :class="[
              flag ? 'topCenter-routers' : 'topCenter-router',
              $route.path === '/qiXunCloud+' ||
              $route.path === '/qiXunBao' ||
              $route.path === '/qiXunCloudAi' ||
              $route.path === '/qiXunScrm' ||
              $route.path === '/productcenter'
                ? 'highlight'
                : '',
              ($route.path === '/qiXunCloud+' ||
                $route.path === '/qiXunBao' ||
                $route.path === '/qiXunCloudAi' ||
                $route.path === '/qiXunScrm' ||
                $route.path === '/productcenter') &&
              flag
                ? 'highlights'
                : '',
            ]"
            to="/qiXunCloud+"
            >产品中心</router-link
          >
          <div class="hover-content">
            <div class="hover-contents" style="text-align: left">
              <div class="">
                <div class="iconfilter">
                  <router-link class="router-link" to="/qiXunCloud+">
                    <div class="flex ai">
                      <div class="iconImg4"></div>
                      <div
                        class="iconfont"
                        style="margin-left: 5px; font-size: 15px"
                      >
                        回拨+ &nbsp;<span style="font-size: 12px"
                          >(回拨、AXB系统)</span
                        >
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="iconfilter">
                  <router-link class="router-link" to="/qiXunBao">
                    <div class="flex ai">
                      <div class="iconImg3"></div>
                      <div
                        class="iconfont"
                        style="margin-left: 5px; font-size: 15px"
                      >
                        群呼 &nbsp;<span style="font-size: 12px"
                          >(电销外呼系统)</span
                        >
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="iconfilter">
                  <router-link class="router-link" to="/qiXunCloudAi">
                    <div class="flex ai">
                      <div class="iconImg5"></div>
                      <div
                        class="iconfont"
                        style="margin-left: 5px; font-size: 15px"
                      >
                        机器人 &nbsp;<span style="font-size: 12px"
                          >(电销机器人)</span
                        >
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="iconfilter">
                  <router-link class="router-link" to="/qiXunScrm">
                    <div class="flex ai">
                      <div class="iconImg1"></div>
                      <div
                        class="iconfont"
                        style="margin-left: 5px; font-size: 15px"
                      >
                        短信 &nbsp;<span style="font-size: 12px"
                          >(智能短信系统)</span
                        >
                      </div>
                    </div>
                  </router-link>
                </div>
                <!-- <div class="iconfilter">
                  <router-link class="router-link" to="/productcenter">
                    <div class="flex ai">
                      <div class="iconImg2"></div>
                      <div
                        class="iconfont"
                        style="margin-left: 5px; font-size: 15px"
                      >
                        私有云 &nbsp;<span style="font-size: 12px"
                          >(服务器部署)</span
                        >
                      </div>
                    </div>
                  </router-link>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="router-hover">
          <router-link
            :class="[
              flag ? 'topCenter-routers' : 'topCenter-router',
              $route.path === '/solution' ||
              $route.path === '/solutionDetails' ||
              $route.path === '/automobileIndustry' ||
              $route.path === '/realEstateIndustry'
                ? 'highlight'
                : '',
              ($route.path === '/solution' ||
                $route.path === '/solutionDetails' ||
                $route.path === '/automobileIndustry' ||
                $route.path === '/realEstateIndustry') &&
              flag
                ? 'highlights'
                : '',
            ]"
            to="/solution"
            >解决方案</router-link
          >
          <!-- <div class="hover-content">
              <div>123</div>
            </div> -->
        </div>
        <div class="router-hover">
          <router-link
            :class="[
              flag ? 'topCenter-routers' : 'topCenter-router',
              $route.path === '/customercase' ? 'highlight' : '',
              $route.path === '/customercase' && flag ? 'highlights' : '',
            ]"
            to="/customercase"
            >客户案例</router-link
          >
          <!-- <div class="hover-content">
              <div>123</div>
            </div> -->
        </div>
        <div class="router-hover">
          <router-link
            :class="[
              flag ? 'topCenter-routers' : 'topCenter-router',
              $route.path === '/customerService' ? 'highlight' : '',
              $route.path === '/customerService' && flag ? 'highlights' : '',
            ]"
            to="/customerService"
            >服务支持</router-link
          >
          <!-- <div class="hover-content">
              <div>123</div>
            </div> -->
        </div>
        <div class="router-hover">
          <router-link
            :class="[
              flag ? 'topCenter-routers' : 'topCenter-router',
              $route.path === '/aboutUsIntroduce' ||
              $route.path === '/aboutUsJoinUs'
                ? 'highlight'
                : '',
              ($route.path === '/aboutUsIntroduce' ||
                $route.path === '/aboutUsJoinUs') &&
              flag
                ? 'highlights'
                : '',
            ]"
            to="/aboutUsIntroduce"
            >关于我们</router-link
          >
          <!-- <div class="hover-content" style="width: 200px">
            <div class="hover-contents" style="text-align: left">
              <div class="">
                <div class="iconfilter">
                  <router-link class="router-link" to="/aboutUsJoinUs">
                    <div class="flex ai">
                      <div class="iconImg6"></div>
                      <div
                        class="iconfont"
                        style="margin-left: 5px; font-size: 15px"
                      >
                        加入我们 &nbsp;<span style="font-size: 12px"></span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- <div class="abc_1 border-red" style="width:1200px;margin: 20px auto;"></div> -->
        <div class="topRight">
          <div
            :class="[flag ? 'topRight1s' : 'topRight1']"
            @click="$router.push('memberlogin')"
          >
            <span class="topRight1-1">会员登录</span>
          </div>
          <div
            :class="[flag ? 'topRight2s' : 'topRight2']"
            @click="dialogVisible = true"
          >
            <span class="topRight2-1">试用申请</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="免费试用申请"
      :visible.sync="dialogVisible"
      width="680px"
      :before-close="handleClose"
    >
      <div class="trial">
        <div class="trial1">
          <div class="trial-1"></div>
          <div class="trial-2">产品选择</div>
          <div class="trial-3">
            <el-select v-model="value" placeholder="请选择" class="selsectbox">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                style="width: 100%; height: 100%"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="trial1" style="margin-top: 40px">
          <div class="trial-1"></div>
          <div class="trial-2">手机号码</div>
          <div class="trial-3">
            <el-input
              v-model="input"
              placeholder="请输入手机号码"
              class="input"
              style="width: 420px; height: 50px"
            ></el-input>
          </div>
        </div>
        <div class="trial1" style="margin-top: 40px">
          <div class="trial-1"></div>
          <div class="trial-2">验证码</div>
          <div style="width: 240px; height: 50px; margin-left: 62px">
            <el-input
              v-model="input1"
              placeholder="请输入验证码"
              class="input2"
              style="width: 240px; height: 50px"
            ></el-input>
          </div>
          <div class="getCode">
            <span style="cursor: pointer">获取验证码</span>
          </div>
        </div>

        <div class="flex" style="margin: 52px 0 0 192px">
          <div>
            <el-button @click="Reset" class="button1">重 置</el-button>
          </div>
          <div>
            <el-button @click="dialogVisible = false" class="button2"
              >提 交</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import eventBus from "../config/eventBus.js";
export default {
  props: {
    // toUrl: {
    //   type: String,
    // },
  },
  data() {
    return {
      dialogVisible: false,
      navList: [
        {
          id: 1,
          first: "首页",
          url: "/homepage",
        },
        {
          id: 2,
          first: "产品中心",
          url: "/productcenter",
        },
        {
          id: 3,
          first: "解决方案",
          url: "/solution",
        },
        {
          id: 4,
          first: "客户案例",
          url: "/customercase",
        },
        {
          id: 5,
          first: "服务支持",
          url: "/",
        },
        {
          id: 6,
          first: "关于我们",
          url: "/aboutUsIntroduce",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "回拨+(回拨、AXB系统)",
        },
        {
          value: "选项2",
          label: "群呼(电销外呼系统)",
        },
        {
          value: "选项3",
          label: "机器人(电销机器人)",
        },
        {
          value: "选项4",
          label: "短信(智能短信系统)",
        },
        // {
        //   value: "选项5",
        //   label: "方大私有云(服务器部署)",
        // },
      ],
      value: "",
      input: "", //手机号
      input1: "", //验证码
      flag: false,
      scrollTops: "",
    };
  },
  watch: {
    // $route(to,form) {
    // console.log(to)
    // console.log(form)
    // this.$router.go(0)
    // }
  },

  mounted() {
    // this.handleScroll();
    // var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    // console.log(document.documentElement.scrollTop,'123')
    // console.log(document.body.scrollTop,'123')
    window.addEventListener("scroll", this.handleScroll, true);
  },
  created() {
    eventBus.$on("add", (message) => {
      this.dialogVisible = message;
    });
  },

  methods: {
    //一级导航跳转
    // goModel(url) {
    //   console.log(url)
    //   localStorage.setItem("toUrlData", url);
    // },
    handleScroll() {
      this.scrollTops =
        document.body.scrollTop || document.documentElement.scrollTop;
      // console.log(this.scrollTops);
      if (this.scrollTops > 0) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    handleClose(done) {
      // this.$confirm('确认关闭？').then(_ => {
      done();
      //     console.log(_)
      //   })
      //   .catch(_ => {
      //     console.log(_)
      //   });
    },
    //重置
    Reset() {
      this.value = "";
      this.input = "";
      this.input1 = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.mainNav {
  width: 100%;
  min-width: 1200px;
  position: fixed;
  top: 0;
  height: 80px;
  display: flex;
  align-items: center;
  z-index: 99;
}

.topLeft {
  width: 257px;
  display: flex;
  .topLeft1 {
    width: 104px;
    height: 40px;
    background-image: url("../assets/02首页版本二/jjj.png");
    background-size: 100% 100%;
    filter: brightness(100);
  }
  .topLeft2 {
    margin-left: 6px;
    .topLeft2-1 {
      height: 23px;
      font-size: 22px;
      font-family: KaiTi;
      font-weight: bold;
      color: #ffffff;
    }
    .topLeft2-2 {
      margin-top: 3px;
      height: 11px;
      font-size: 13px;
      font-family: KaiTi;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.topRight {
  width: 308px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.topRight1 {
  margin-right: 10px;
  width: 88px;
  height: 32px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  text-align: center;
  line-height: 34px;
  span {
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
}
.topRight1-1 {
  cursor: pointer;
}
.topRight2 {
  width: 90px;
  height: 34px;
  background: #ffffff;
  border-radius: 4px;
  text-align: center;
  line-height: 34px;
  span {
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ff6a00;
  }
}
.topRight2-1 {
  cursor: pointer;
}

/* 试用申请 */
.trial {
  width: 100%;
  height: 390px;
}
.trial1 {
  display: flex;
  align-items: center;
}
.trial-1 {
  margin: 0 19px 0 46px;
  width: 12px;
  height: 12px;
  background-image: url("../assets/23试用申请/+@2x.png");
}
.trial-2 {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1a1a1a;
  line-height: 20px;
}
.trial-3 {
  width: 420px;
  height: 50px;
  margin-left: 41px;
}
/deep/ .selsectbox .el-input--suffix .el-input__inner {
  width: 420px;
  height: 50px;
  border-radius: 5px;
  background: #ffffff !important;
  border: 1px solid #d7d7d7 !important;
}
/deep/ .input .el-input__inner {
  width: 420px;
  height: 50px;
  border-radius: 5px;
  background: #ffffff !important;
  border: 1px solid #d7d7d7 !important;
}
/deep/ .input2 .el-input__inner {
  width: 240px;
  height: 50px;
  border-radius: 5px;
  background: #ffffff !important;
  border: 1px solid #d7d7d7 !important;
}
.getCode {
  width: 160px;
  height: 50px;
  background: #ff900e;
  border-radius: 2px;
  margin-left: 20px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
}
.button1 {
  width: 180px;
  height: 50px;
  background: #d0d0d0;
  border-radius: 8px;
}
.button2 {
  margin-left: 60px;
  width: 180px;
  height: 50px;
  background: linear-gradient(0deg, #fe6747 0%, #fe9345 100%);
  border-radius: 8px;
  color: #ffffff;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  height: 80px;
}
.mainNavs {
  background: #ffffff;
  width: 100%;
  min-width: 1200px;
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0 2px 3px 0 #00000010, 0 2px 3px 0 #00000010;
}
.mainNavs .topLeft1 {
  filter: brightness(0);
}
.mainNav:hover {
  background: #ffffff;
}
.mainNav:hover .topLeft1 {
  filter: brightness(0);
}
.mainNav:hover .topRight1 {
  border: 1px solid black;
}
.mainNav:hover .topRight2 {
  border: 1px solid black;
}
.mainNav:hover .highlight {
  border-bottom: 2px solid rgb(101, 92, 92);
}
.mainNav:hover .topCenter-router,
.mainNav:hover .topLeft2-1,
.mainNav:hover .topLeft2-2,
.mainNav:hover .topRight1-1 {
  color: black;
}
.router-hover {
  height: 80px;
}
.topLeft2-1s {
  height: 23px;
  font-size: 22px;
  font-family: KaiTi;
  font-weight: bold;
  color: black;
}
.topLeft2-2s {
  margin-top: 3px;
  height: 11px;
  font-size: 13px;
  font-family: KaiTi;
  font-weight: 400;
  color: black;
}
.topCenter-routers {
  height: 80px;
  font-size: 16px;
  text-decoration: none;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: black;
  line-height: 80px;
}
.topRight1s {
  margin-right: 10px;
  width: 88px;
  height: 32px;
  border: 1px solid black;
  border-radius: 4px;
  text-align: center;
  line-height: 34px;
  span {
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: black;
  }
}
.topRight2s {
  width: 90px;
  height: 34px;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 4px;
  text-align: center;
  line-height: 34px;
  span {
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ff6a00;
  }
}
.topCenter-router {
  height: 80px;
  font-size: 16px;
  text-decoration: none;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 80px;
}
.router-hover:hover .topCenter-routers,
.router-hover:hover .topCenter-router {
  color: #fe9345;
}
.topLeft2-1:hover + .abc_1 {
  height: 60px;
  background: #ffffff;
}
.abc_1 {
  height: 20px;
}
.hover-content {
  display: none;
  width: 260px;
  position: absolute;
  box-shadow: 0 2px 3px 0 #00000010, 0 2px 3px 0 #00000010;
  border-top: 1px solid #cccccc;
}
.hover-contents {
}
.iconfilter:hover .iconImg6,
.iconfilter:hover .iconImg5,
.iconfilter:hover .iconImg4,
.iconfilter:hover .iconImg3,
.iconfilter:hover .iconImg2,
.iconfilter:hover .iconImg1 {
  filter: none;
}
.iconfilter:hover .iconfont {
  font-size: 16px;
  color: #fe9345;
}
.router-hover:hover .hover-content {
  display: block;
  background: #ffffff;
}
.router-link {
  height: 46px;
  font-size: 18px;
  text-decoration: none;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: black;
  line-height: 46px;
}
.iconImg1 {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  background-image: url("../assets/02首页版本二/SCRM.png");
  background-size: 100% 100%;
  filter: brightness(0);
}
.iconImg2 {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background-image: url("../assets/02首页版本二/fangdayun.png");
  background-size: 100% 100%;
  filter: brightness(0);
}
.iconImg3 {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background-image: url("../assets/02首页版本二/qixunbao.png");
  background-size: 100% 100%;
  filter: brightness(0);
}
.iconImg4 {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background-image: url("../assets/02首页版本二/yun+.png");
  background-size: 100% 100%;
  filter: brightness(0);
}
.iconImg5 {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background-image: url("../assets/02首页版本二/yunAI.png");
  background-size: 100% 100%;
  filter: brightness(0);
}
.iconImg6 {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background-image: url("../assets/02首页版本二/加入组织.png");
  background-size: 100% 100%;
  filter: brightness(0);
}
.highlight {
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(255, 255, 255);
}
.highlights {
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(101, 92, 92);
}
</style>
